.wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 50px 0;

  .headTitle {
    font-weight: 600;
    font-size: 20px;
    text-align: center;
  }

  .item {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 30px;
    gap: 5px;
    text-align: center;

    .title {
      font-weight: 700;
    }
  }
  a{
    color: #c165a4;
    font-weight: 500;
    &:hover{
      color: #7a4679;
      text-decoration: underline;
    }
  }
}