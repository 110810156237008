.wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 50px 0;
}
.wrapper .headTitle {
  font-weight: 600;
  font-size: 20px;
  text-align: center;
}
.wrapper .item {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
  gap: 5px;
  text-align: center;
}
.wrapper .item .title {
  font-weight: 700;
}
.wrapper a {
  color: #c165a4;
  font-weight: 500;
}
.wrapper a:hover {
  color: #7a4679;
  text-decoration: underline;
}

